import { Link } from "gatsby"
import React from "react"
import { ReactComponent as FlagUSA } from '../images/svg/002-united-states.svg'
import { ReactComponent as FlagMexico } from '../images/svg/012-mexico.svg'
import { ReactComponent as FlagIndia } from '../images/svg/011-india.svg'
import { ReactComponent as FlagGreece } from '../images/svg/019-greece.svg'
import { ReactComponent as FlagItaly } from '../images/svg/007-italy.svg'
import { ReactComponent as FlagChina } from '../images/svg/006-china.svg'
import { ReactComponent as FlagKorea } from '../images/svg/009-south-korea.svg'
import { ReactComponent as FlagUK } from '../images/svg/001-united-kingdom.svg'
import { ReactComponent as FlagFrance } from '../images/svg/003-france.svg'
import { ReactComponent as FlagGermany } from '../images/svg/004-germany.svg'
import { ReactComponent as FlagJapan } from '../images/svg/008-japan.svg'
import { ReactComponent as FlagJamaica } from '../images/svg/033-jamaica.svg'

const RegionalLink = ({ region }) => {
  let regionSlug = region.toLowerCase();
  return (
  <Link to={`/tag/${regionSlug}`}>
    <div className="group mb-4 text-center rounded-full hover:shadow-lg object-cover outline-none h-12 w-12 md:h-20 md:w-20 2xl:h-28 2xl:w-28">
    {
      {
        'American' : <FlagUSA />,
        'Mexican' : <FlagMexico />,
        'Indian' : <FlagIndia />,
        'Greek' : <FlagGreece />,
        'Italian' : <FlagItaly />,
        'Chinese' : <FlagChina />,
        'Korean' : <FlagKorea />,
        'UK' : <FlagUK />,
        'French' : <FlagFrance />,
        'German' : <FlagGermany />,
        'Japanese' : <FlagJapan />,
        'Jamaican' : <FlagJamaica />,
      }[region]
    }
      <span className="text-sm md:text-base xl:text-xl text-center group-hover:text-red-700 opacity-70 pt-2">{region}</span>
    </div>
  </Link>
  )
}

export default RegionalLink