import { Link } from "gatsby"
import React from "react"

const CircularImageLink = ({ title, imgSrc, link }) => {
  let linkSlug = link === undefined ? title.toLowerCase() : link.toLowerCase();
  return (
    <Link to={`/tag/${linkSlug}`}>
      <div className="group text-center justify-items-center outline-none focus:outline-none">
        <img src={imgSrc} alt={`${title}`} className="rounded-full hover:shadow-lg object-cover outline-none h-12 w-12 md:h-20 md:w-20 2xl:h-28 2xl:w-28" />
        <span className="text-sm md:text-base xl:text-xl text-center group-hover:text-red-700 opacity-70 pt-2">{title}</span>
      </div>
    </Link>
  )
}

export default CircularImageLink