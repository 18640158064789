import React from "react"
import Layout from "../components/layout"
import CircularImageLink from "../components/circularImageLink"
import RegionalLink from "../components/regionalLink"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="contentContainer">
        <h2 className="py-5">What's cooking?</h2>
        <div className="indexGrid">
          <CircularImageLink title="Appetizers" link="appetizer" imgSrc="https://simply-delicious-food.com/wp-content/uploads/2018/10/breakfast-board-2.jpg" />
          <CircularImageLink title="Breakfast" imgSrc="https://simply-delicious-food.com/wp-content/uploads/2018/10/breakfast-board-2.jpg" />
          <CircularImageLink title="Lunch" imgSrc="https://simply-delicious-food.com/wp-content/uploads/2020/07/Easy-salad-sandwiches-with-herb-mayo-5.jpg" />
          <CircularImageLink title="Dinner" imgSrc="https://images.immediate.co.uk/production/volatile/sites/30/2019/12/tandoori-trout-bb9fe6c.jpg" />
          {/* <CircularImageLink title="Dessert" imgSrc="https://realhousemoms.com/wp-content/uploads/Strawberry-Cheesecake-IG.jpg" /> */}
        </div>
        <h3 className="px-5 pt-5">By Region</h3>
        <div className="indexGrid">
          <RegionalLink region="American" />
          <RegionalLink region="Mexican" />
          <RegionalLink region="Indian" />
          <RegionalLink region="Greek" />
          <RegionalLink region="Italian" />
          <RegionalLink region="Chinese" />
          <RegionalLink region="French" />
          <RegionalLink region="German" />
        </div>
      </div>
  </Layout>
)

export default IndexPage